/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useEffect, useState, Fragment } from "react";
import { Link } from "gatsby";
import scrollToElement from "scroll-to-element";
import Particles from "react-tsparticles";
import { Fade } from "react-awesome-reveal";
import LazyLoad from "react-lazyload";

import { Layout, MddNav } from "../../components";
import MddForm from "../../components/MddForm";
import ClientsSlider from "../../components/ClientsSlider";

import locales from "../../constants";

const chart_data = [
  {
    title: "Marketing[br]strategy",
    description:
      "Our strategies are derived from 14 years of experience in the marketing industry and specialization in the real estate sector. Thanks to our strategy, we operate based on data, not guesses, and the brand and communication created from it are guided through your business world on the fastest path to optimal sales.",
  },
  {
    title: "Naming",
    description:
      "Why is it worth entrusting us with the development of names? Because it is a complex process that often requires experience. A good name is not just a catchy phrase that looks nice and satisfies language purists. What matters are the emotions it evokes, its relevance, or the subtle conveyance of the right values when literalness is not an option. The process concludes with research on the availability of the name in specific domains and in national and European online databases.",
  },
  {
    title: "Logo[br]and visual[br]identity",
    description:
      "We offer the creation of both individual materials and comprehensive visual identity projects, including the full adaptation of the logo and key visual graphic theme in your marketing materials. This includes apartment cards, branding of the construction site, and the sales office. We ensure that your image is consistent, cohesive, and therefore effective.",
  },
  {
    title: "Advertising[br]slogans",
    description:
      "Wyznajemy zasadę, że jeśli reklama nie sprzedaje produktu, to nie jest kreatywna. Dobre copy to nie tylko błyskotliwa kreacja, ale przede wszystkim sprzedażowa skuteczność wynikająca z czytelności i adekwatności tekstu wobec atutów oferty oraz gustu i potrzeb Twojego odbiorcy.",
  },
  {
    title: "Website",
    description:
      "We design websites that convert anonymous traffic into thousands of valuable leads for your sales department. Our projects combine intuitive structure, unique graphics that differentiate your brand in the market, functional solutions like apartment navigators with contact forms, and high-quality technologies ensuring smooth operation and protection against attacks.",
  },
  {
    title: "Social[br]media",
    description:
      "We don't believe in shortcuts; for us, it's not just about reach but also authentic and creative social channel moderation, as well as genuine audience engagement. That's why, within 2 years, we've built a community of 30,000 fans for a hotel in Szklarska Poręba on Facebook. More importantly, this traffic has translated into real sales of condo-hotel investments and increased brand awareness among B2C audiences.",
  },
  {
    title: "Marketing[br]materials",
    description:
      "We will help you build a wide range of marketing tools - from flyers and banners to campaigns, outdoor advertising, apartment cards, and the branding of your sales office.",
  },
  {
    title: "Investment[br]campaign",
    description:
      "Why do we love Google Ads and Facebook Ads campaigns? Because they provide data, and where there's data, there's room for analysis and optimization. When numbers meet our experience and creativity, the results are astounding! Thanks to our strategies, meticulously designed materials, and campaigns, we have successfully promoted nearly 50 development projects to date, resulting in the sale of over 10,000 apartments or houses.",
  },
];

const MarketingDD = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";
  const particlesParams = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      size: {
        value: 3,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "bubble",
        },
        onclick: {
          enable: true,
          mode: "repulse",
        },
      },
      modes: {
        bubble: {
          distance: 250,
          duration: 2,
          size: 0,
          opacity: 0,
        },
        repulse: {
          distance: 400,
          duration: 4,
        },
      },
    },
  };

  const [isScrolled, setIsScrolled] = useState(true);

  // Initial effect
  useEffect(() => {
    const heroClouds = document.getElementById("heroClouds");
    const layerTwo = document.getElementById("heroLayerTwo");
    const layerOne = document.getElementById("heroLayerOne");
    const foreground = document.getElementById("heroFg");

    const handleScroll = () => {
      const value = window.scrollY;

      heroClouds.style.left = -value * 0.05 + "px";
      heroClouds.style.top = value * 0.05 + 40 + "px";
      layerTwo.style.top = -value * 0.15 + "px";
      layerOne.style.scale = value * 0.00001 + 1;
      layerOne.style.top = value * 0.3 + "px";
      foreground.style.top = value * 0.05 + "px";
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Spinning circle effect
  const [chartPie, setChartPie] = useState(0);
  const [chartPieAutoplay, setChartPieAutoplay] = useState(true);

  useEffect(() => {
    let interval;
    let timeout;

    clearTimeout(timeout);
    clearInterval(interval);

    if (chartPieAutoplay) {
      interval = setInterval(() => {
        setChartPie((prevPie) =>
          prevPie + 1 >= chart_data.length ? 0 : prevPie + 1
        );
      }, 7000);
    } else {
      timeout = setTimeout(() => {
        setChartPieAutoplay(true);
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [chartPieAutoplay]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 1500 ? false : true);
    });
  });

  return (
    <>
      <Layout
        seo={{
          title: "Marketing for real estate developers",
          href: slug,
          lang: "en",
        }}
        header={{ icons: "#eab941" }}
        langSwitch={{
          langKey: "pl",
          langSlug: "/marketing-dla-deweloperow/",
        }}
      >
        <section className="container-fluid mdd-hero">
          <Fade direction="down" delay="30" triggerOnce="true">
            <h1 className="mdd-header mdd-header--1">Marketing for</h1>
          </Fade>
          <Fade direction="down" delay="60" triggerOnce="true">
            <h1 className="mdd-header mdd-header--2">real estate developers</h1>
          </Fade>
          <img
            src={require("../../assets/img/mdd/hero/background.png")}
            className="img-fluid parallax-img"
            id="heroBackground"
            alt="Fala w tle"
          />
          <img
            src={require("../../assets/img/mdd/hero/clouds.png")}
            className="img-fluid parallax-img"
            id="heroClouds"
            alt="Chmury"
          />
          <img
            src={require("../../assets/img/mdd/hero/layer2.png")}
            className="img-fluid parallax-img"
            id="heroLayerTwo"
            alt="Miasto w tle"
          />
          <img
            src={require("../../assets/img/mdd/hero/layer1.png")}
            className="img-fluid parallax-img"
            id="heroLayerOne"
            alt="Miasto i ludzie"
          />
          <img
            src={require("../../assets/img/mdd/hero/foreground.png")}
            className="img-fluid parallax-img"
            id="heroFg"
            alt="Linia krzewów"
          />
          <button
            className="scrollPortfolio"
            onClick={() => scrollToElement("#intro")}
          />
        </section>

        <Particles
          className={`mdd-particles-wrapper${!isScrolled ? " hidden" : ""}`}
          params={particlesParams}
        />

        <section className="container-fluid mdd-section-1" id="intro">
          <div className="row">
            <div className="col-lg-5">
              <div className="inner">
                <h1>
                  Dozens of promoted investments, thousands of apartments sold
                </h1>
                <h3>
                  We specialize in comprehensive and effective marketing of
                  development projects.
                </h3>
                <p>
                  We will assist you at every stage of your brand development
                  and sales process. We will build a strategy that addresses
                  your image and sales challenges, create a unique brand design
                  to help you stand out in the market, design an intuitive and
                  modern website using the latest technologies and equipped with
                  a convenient apartment search engine. If your brand already
                  has a personality and tools, we will execute an effective
                  promotional campaign that will transform passive online
                  activity into thousands of sales leads.
                </p>
                <a
                  className="btn_custom"
                  type="button"
                  href="https://docs.google.com/presentation/d/1z7tzq7dPhaWSLIyh2BgVi4BR-0aGHsRyCcuF1a_rHGg/edit#slide=id.g21f27b53a76_0_0"
                  target="_blank"
                  rel="noreferrer"
                >
                  See our developer presentation
                </a>
              </div>
            </div>
            <div className="col-lg-5 offset-md-2">
              <LazyLoad once height={392}>
                <Fade direction="right" delay="30" triggerOnce="true">
                  <img
                    src={require("../../assets/img/mdd/section_1.png")}
                    className="img-fluid"
                    alt="Man with magnifier and smartphone"
                  />
                </Fade>
              </LazyLoad>
            </div>
          </div>
        </section>
        <section className="container-fluid mdd-section-2">
          <h1>What can we do for you?</h1>
          <h3>Choose comprehensive marketing or individual actions</h3>
          <div className="row">
            <div className="col-lg-6">
              <div className="menunav">
                <ul className="circle">
                  {chart_data.map((item, index) => (
                    <li
                      className={`pie-chart-${index + 1} slice ${
                        chartPie !== null && chartPie === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        type="button"
                        className="circle"
                        onClick={() => {
                          setChartPie(index);
                          setChartPieAutoplay(false);
                        }}
                      >
                        <span
                          className="slice__element"
                          style={{ transform: `rotate(${20 - 45 * index}deg)` }}
                        >
                          {item.title.split("[br]").map((item, index) => (
                            <Fragment key={index}>
                              {index !== 0 ? <br /> : null}
                              {item}
                            </Fragment>
                          ))}
                        </span>
                      </button>
                    </li>
                  ))}
                  <li className="unsel circle">
                    <p className="clicky menuname">360&deg;</p>
                  </li>
                  <li className="middle circle"></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="inner">
                {chart_data
                  .filter((_, index) => chartPie !== null && chartPie === index)
                  .map((item, index) => (
                    <div className="menu-text-paragraph active" key={index}>
                      <h3>{item.title.replaceAll("[br]", " ")}</h3>
                      <p>{item.description}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid mdd-section-05">
          <div className="row align-items-lg-center">
            <div className="col-lg-4">
              <LazyLoad once height={213}>
                <Fade direction="left" delay="30" triggerOnce="true">
                  <img
                    src={require("../../assets/img/mdd/paragraph.png")}
                    className="img-fluid"
                    alt="Kobieta i mężczyzna na tle sklepu internetowego"
                  />
                </Fade>
              </LazyLoad>
            </div>
            <div className="offset-lg-1 col-lg-7">
              <div className="inner">
                <h1>Why Us?</h1>
                <p>
                  We have extensive experience in comprehensive marketing
                  support for real estate development investments. Equally
                  important, we simply love marketing, and we have been
                  passionate about the real estate market for many years. Thanks
                  to this combination, we managed to achieve phenomenal results
                  for multiple Clients.
                </p>
                <p>
                  We have conducted several dozen integrated marketing campaigns
                  for well recognized real estate developers - check our case
                  studies and&nbsp;
                  <a href="#mddPortfolio">portfolio</a>.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mdd-section-2">
          <h1>They trust us</h1>
          <h3>We know how to run investment campaigns</h3>
          <ClientsSlider lang="en" />
        </section>

        <section
          id="mddPortfolio"
          className="container-fluid mdd-section-carousel"
        >
          <h1 className="nav-carousel">Portfolio</h1>
          <h3 className="nav-carousel">See our projects</h3>
          <LazyLoad once height={583} offset={100}>
            <MddNav data={nav} locales={locales[lang]} />
          </LazyLoad>
        </section>

        <section className="container-fluid mdd-section-3" id="mddCaseStudies">
          <div>
            <h1>Documented successes</h1>
            <h3>See our case studies!</h3>
          </div>
          {/* Komponent ze sliderem  - zamieniz za poniższy div.row */}
          {/* <CaseStudySlider/> */}
          <LazyLoad once height={1069} offset={100}>
            <div className="row">
              <div className="col-lg-6">
                <Link to="/case-study/imperial-citi-yes">
                  <div className="mdd-image-container">
                    <img
                      src={require("../../assets/img/mdd/thumbnail_1.png")}
                      className="img-fluid"
                      alt="Ogród na dachu bloku"
                    />
                    <p className="img-text">
                      <span>90 umów w</span>
                      <br />
                      <span>90 dni</span>
                    </p>
                  </div>
                </Link>
                <div className="inner">
                  <Link to="/case-study/imperial-citi-yes">
                    <h4 className="when-link">
                      Case study kampanii Google i Facebook Ads inwestycji
                      Imperial Citi Yes
                    </h4>
                  </Link>
                  <p>
                    Specjalizujemy się w kompleksowym marketingu inwestycji
                    deweloperskich, bo uwielbiamy marketing i interesujemy się
                    rynkiem RE. Przeprowadziliśmy kilkadziesiąt zintegrowanych
                    kampanii dla uznanych deweloperów. Dbamy o odpowiedni
                    wizerunek inwestycji i marki dewelopera, ale przede
                    wszystkim zależy nam na wymiernej skuteczności prowadzonych
                    działań. Właśnie dlatego pomogliśmy naszym Klientom w
                    sprzedaży ponad 5000 jednostek
                  </p>
                  <Link
                    to="/case-study/imperial-citi-yes"
                    className="btn_custom"
                  >
                    See more
                  </Link>
                </div>
              </div>

              <div className="col-lg-6">
                <Link to="/projekty/imperial-stawowa-residence/">
                  <div className="mdd-image-container">
                    <img
                      src={require("../../assets/img/mdd/thumbnail_2.png")}
                      className="img-fluid"
                      alt="Samochód przed nowoczesnym domem"
                    />
                    <p className="img-text">
                      <span>120 mieszkań</span>
                      <br />
                      <span>100% realizacji</span>
                      <br />
                      <span>celów!</span>
                    </p>
                  </div>
                </Link>
                <div className="inner">
                  <Link to="/projekty/imperial-stawowa-residence/">
                    <h4 className="when-link">
                      Google i Facebook Ads dla 3 etapów inwestycji
                      mieszkaniowej Imperial Stawowa Residence
                    </h4>
                  </Link>
                  <p>
                    Specjalizujemy się w kompleksowym marketingu inwestycji
                    deweloperskich, bo uwielbiamy marketing i interesujemy się
                    rynkiem RE. Przeprowadziliśmy kilkadziesiąt zintegrowanych
                    kampanii dla uznanych deweloperów. Dbamy o odpowiedni
                    wizerunek inwestycji i marki dewelopera, ale przede
                    wszystkim zależy nam na wymiernej skuteczności prowadzonych
                    działań. Właśnie dlatego pomogliśmy naszym Klientom w
                    sprzedaży ponad 5000 jednostek
                  </p>
                  <Link
                    to="/projekty/imperial-stawowa-residence/"
                    className="btn_custom"
                  >
                    See more
                  </Link>
                </div>
              </div>
            </div>
          </LazyLoad>
        </section>

        <section className="container-fluid mdd-contact-form">
          <div className="row">
            <div className="col-md-4">
              <h1>Let's talk about the success of your investment.</h1>
              <p>
                Leave your contact details and we will contact you within 48
                hours.
              </p>
              <p>
                We specialize in comprehensive marketing of development
                investments, because we love marketing and we are interested in
                the real estate market. That is why we have helped our clients
                to sell almost 10,000 real estate units.
              </p>
            </div>
            <div className="col-md-8">
              <MddForm locales={locales[lang]} lang={lang} />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default MarketingDD;
